import React from "react";
import Header from "../Components/Header";
import UnsubViewBody from "../Components/UnsubViewBody";
import Login from "./Login";

const ViewUnsub=()=>{

    let username=sessionStorage.getItem("username");

    if(username!==null)
    {
        return(
            <div>
                <Header/>
                <UnsubViewBody/>
            </div>
        );
    }
    else
    {
        return(
            <div>
                <Login/>
            </div>
        );
    }

}
export default ViewUnsub;