import React,{useState,useEffect} from "react";
import axios from "axios";
// import Chart from "react-apexcharts";
// import ApexCharts from "apexcharts";
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import '@progress/kendo-theme-default/dist/all.css';
import { showMis,getLastDaysGraph,showFullGraph } from "../Apis/api";
import TableMis from "../SubComponents/TableMis";
import spinner from "../Images/spinner.svg";
import "../Css/spinner.css";
import "../Css/home.css";

const RevenueBodyNew=()=>{
    useEffect(()=>{
        hitOnApi(0,10);
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        console.log("date is ",date);

        let priorDate = new Date(new Date().setDate(today.getDate() - 30));
        console.log("priorDate",priorDate);
        let pDate=priorDate.getFullYear()+'-'+(priorDate.getMonth()+1)+'-'+priorDate.getDate();
        console.log("pdate ",pDate);
        
        let data={
            "fromDate":pDate,
            "toDate":date
        }

        console.log("data", data);

        hitForNowGraph(data);
        
        setTimeout(()=>{
            document.getElementById("spin").style.display="none";
        },1000);

    },[]);

    const[data,setData]=useState([]);
    const[cPage,setCurrentPage]=useState();
    const[tPage,setTotalPages]=useState();
    const[graphData,addGraphData]=useState({});

    const hitOnApi=(pageNumber, pageSize)=>{
        axios.get(`${showMis}`+"?pageNumber="+pageNumber)
        .then(
            (response)=>{
                // console.log(response.data);
                let content=response.data.content;
                let totalPages=response.data.totalPages;
                let currentPage=response.data.currentPage;

                // console.log("content: ",content);
                // console.log("totalPages: ",totalPages);
                // console.log("currentPage: ",currentPage);

                setData(content);
                setCurrentPage(currentPage);
                setTotalPages(totalPages);

                if(currentPage===0)
                {
                    document.getElementById("subPrevious").style.display="none";
                }
                else
                {
                    document.getElementById("subPrevious").style.display="block";
                }

                if(currentPage===totalPages)
                {
                    document.getElementById("subNext").style.display="none";
                }
                else
                {
                    document.getElementById("subNext").style.display="block";
                }
            },
            (error)=>{
                console.log(error)
            }
        )
    };

    const[myGraph,addMyGraphData]=useState({});

    const hitForNowGraph=(data)=>{
        axios.post(`${showFullGraph}`,data)
        .then(
            (response)=>{
                console.log(response.data)
                // addGraphData(response.data.graphData);
                // addGraphData(response.data.graphData);
                addMyGraphData(response.data);

                // console.log("myGraph",myGraph);
            },
            (error)=>{
                console.log(error);
            }
        )
    }

    return(
        <div className="home-bg">

        <div className="spinner" id="spin">
            <img className="spinner-item" src={spinner}/>
        </div>


        <div style={{textAlign:"center", marginTop:"50px"}}>
                        <button className="btn btn-success">
                            Last Days Revenue
                        </button>
        </div>

        {/* <div id="chart" className="container">
        </div> */}

        {/*Chart */}

        <div className="container">

        <label className="font-graph">Sub100</label>
        <label className="graph-color-div" style={{backgroundColor:"red"}}> </label>
        
        <label className="left-margin-graph font-graph">Sub500</label>
        <label className="graph-color-div" style={{backgroundColor:"green"}}> </label>

        <label className="left-margin-graph font-graph">Ren100</label>
        <label className="graph-color-div" style={{backgroundColor:"blue"}}> </label>

        <label className="left-margin-graph font-graph">Ren500</label>
        <label className="graph-color-div" style={{backgroundColor:"orange"}}> </label>

        <Chart>
            <ChartTitle text="Count" />
                <ChartCategoryAxis>
                <ChartCategoryAxisItem
                    title={{
                    text: "Dates",
                    }}
                    // categories={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"]}
                    categories={myGraph.Dates}
                    />
    
                </ChartCategoryAxis>
            <ChartSeries>
                <ChartSeriesItem type="line" color="red" data={myGraph.Sub100} tooltip= {{
                        shared: true,
                        visible: true,
                        background: "green",
                        // template: "#= series.stack.group #, city #= series.name #",
                        template:"Sub 100",
                      
                     }}/>
                <ChartSeriesItem type="line" color="green" data={myGraph.Sub500} tooltip= {{
                        shared: true,
                        visible: true,
                        background: "red",
                        template: "#= series.stack.group #, city #= series.name #"
                     }}/>
                <ChartSeriesItem type="line" color="blue" data={myGraph.Ren100} tooltip= {{
                        shared: true,
                        visible: true,
                        background: "red",
                        template: "#= series.stack.group #, city #= series.name #"
                     }}/>
                <ChartSeriesItem type="line" color="orange" data={myGraph.Ren500} tooltip= {{
                  shared: true,
                  visible: true,
                  background: "red",
                  template: "#= series.stack.group #, city #= series.name #"
                    }}/>
            </ChartSeries>
      </Chart>
        </div>


             <div className="container"> {/*tbl_div*/}
                <TableMis data={data}/>
            </div>
            <div> {/*Pagination div*/}
                <nav aria-label="Page navigation example" id="subNav">
                    <ul className="pagination center">
                        <li className="page-item" id="subPrevious"><button className="page-link" onClick={()=>hitOnApi(cPage-1)}>Previous</button></li>
                        <li className="page-item"><button className="page-link" onClick={()=>hitOnApi(cPage)}>{cPage+1}</button></li>
                        <li className="page-item"><button className="page-link" onClick={()=>hitOnApi(cPage+1)}>{cPage+2}</button></li>
                        <li className="page-item"><button className="page-link" onClick={()=>hitOnApi(cPage+2)}>{cPage+3}</button></li>
                        <li className="page-item" id="subNext"><button className="page-link" onClick={()=>hitOnApi(cPage+1)}>Next</button></li>
                    </ul>
                 </nav>
             </div>            
        </div>
    );
}
export default RevenueBodyNew;