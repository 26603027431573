import axios from "axios";
import React from "react";
import { unsubUser,addToDnd } from "../Apis/api";
import "../Css/common.css";
import {ToastContainer, toast } from 'react-toastify';

const Table=(prop)=>{

    //method to handle unsub button
    const unsubMethod=(ani,service)=>{

        let send=
        {
            "ani":ani,
            "service":service
        };

        hitOnApi(unsubUser,send);
    };

    //Method to handle dnd button
    const dndMethod=(ani,service)=>{

        let send=
        {
            "ani":ani,
            "service":service
        }

        hitOnApi(addToDnd,send);
    };

    //Method to hit on api
    const hitOnApi=(url,send)=>{
        axios.post(`${url}`,send)
        .then(
            (response)=>{
                console.log(response.data.response);
                console.log(response.data.message);
                toast.success(response.data.message);
            },
            (error)=>{
                console.log(error);
                toast.error("Something Went Wrong");
            }
        )
    };

    return(
    <div>
        <ToastContainer/>
        <table className="table table-dark table-bordered">
                <thead>
                    <tr>
                        <th>MSISDN</th>
                        <th>SubDate</th>
                        <th>M act</th>
                        <th>Service</th>
                        <th>Next Billed Date</th> 
                        <th>Action</th>
                    </tr>
                </thead>
                {prop.data.map((tbl,index)=>{
                    return(
                        <tbody key={index}>
                            <tr key={index}>
                                <td>{tbl.ani}</td>
                                <td>{tbl.sub_date_time}</td>
                                <td>{tbl.m_act}</td>
                                <td>{tbl.service_type}</td>
                                <td>{tbl.next_billed_date}</td>
                                <td><button className="btn btn-primary"
                                onClick={()=>{
                                    unsubMethod(tbl.ani,tbl.service_type)
                                }}>
                                    Unsub
                                    </button>
                                <button className="btn btn-success mLeftSmall"
                                onClick={()=>{
                                    dndMethod(tbl.ani,tbl.service_type);
                                }}>
                                    DND
                                </button></td>
                            </tr>
                        </tbody>
                    )
                })}
            </table>
    </div>
    );
}

export default Table;