import React from "react";
import Header from "../Components/Header";
// import RevenueBody from "../Components/RevenueBody";
import RevenueBodyNew from "../Components/RevenueBodyNew";
import Login from "./Login";

const Revenue=()=>{

    let username=sessionStorage.getItem("username");

    if(username!==null)
    {
        if(username==='superadmin')
        {
            //then show that data
            return(
                <div>
                    <Header/>
                    {/* <RevenueBody/> */}
                    <RevenueBodyNew/>
                 </div>
            );
        }
        else
        {
            return(
                <div>
                    <Login/>
                </div>
            );
        }
    }
    else
    {
        return(
            <div>
                <Login/>
            </div>
        );
    }
}
export default Revenue;