import axios from "axios";

const SearchApi=async(url,search,searchType,pageNumber,pageSize)=>{
    let result={};

    await axios.get(`${url}?pageNumber=${pageNumber}&${searchType}=${search}`)
    .then(
        (response)=>{
            console.log("response",response);
            result={...response.data};
        },
        (error)=>{
            console.log(error);
        }
    )
        return result;
}
export default SearchApi;