import React from "react";
import "../Css/common.css";

const TableUnsub=(prop)=>{
    return(
    <div>
        <table className="table table-dark table-bordered">
                <thead>
                    <tr>
                        <th>MSISDN</th>
                        <th>SubDate</th>
                        <th>UnsubDate</th>
                        <th>Service</th>
                        <th>Amount</th> 
                        <th>M_Act</th>
                        <th>M_Deact</th>
                    </tr>
                </thead>
                {prop.data.map((tbl,index)=>{
                    return(
                        <tbody key={index}>
                            <tr>
                                <td>{tbl.ani}</td>
                                <td>{tbl.sub_date_time}</td>
                                <td>{tbl.unsub_date_time}</td>
                                <td>{tbl.service_type}</td>
                                <td>{tbl.default_amount}</td>
                                <td>{tbl.m_act}</td>
                                <td>{tbl.m_deact}</td>
                            </tr>
                        </tbody>
                    )
                })}
            </table>
    </div>
    );
}

export default TableUnsub;