import React, { useState, useEffect } from "react";
import axios from "axios";
import Login from "./Login";
import "../Css/graph.css";
import Header from "../Components/Header";
import { subUnsubGraph } from "../Apis/api";
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import "@progress/kendo-theme-default/dist/all.css";

const SubUnsubGraph = () => {
  let username = sessionStorage.getItem("username");
  const [graphData, addGraphData] = useState({});

  useEffect(() => {
    let today = new Date();

    let add = "";
    if (
      today.getMonth() + 1 !== 10 ||
      today.getMonth() + 1 !== 11 ||
      today.getMonth() + 1 !== 12
    ) {
      add = "-0";
    } else {
      add = "-";
    }

    let date =
      today.getFullYear() +
      add +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    console.log("date is ", date);

    let priorDate = new Date(new Date().setDate(today.getDate() - 30));
    console.log("priorDate", priorDate);
    let pDate =
      priorDate.getFullYear() +
      add +
      (priorDate.getMonth() + 1) +
      "-" +
      priorDate.getDate();
    console.log("pdate ", pDate);

    let data = {
      fromDate: pDate,
      toDate: date,
    };

    console.log("data ", data);

    hitOnApi(data);
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    console.log(graphData);
    //hit on api
    hitOnApi(graphData);
  };

  const hitOnApi = (graphData) => {
    axios.post(`${subUnsubGraph}`, graphData).then(
      (response) => {
        // console.log(response);
        console.log(response.data);
        addGraphData(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  if (username !== null) {
    return (
      <div>
        <Header />
        <div className="mTop-graph mm-bg">
          {/* <div className="sub-main"> */}
          <form onSubmit={submitForm} className="main-graph">
            <div>
              <input
                type="date"
                name="fromDate"
                className="search-graph"
                id="fromDate"
                required
                onChange={(e) => {
                  addGraphData({ ...graphData, fromDate: e.target.value });
                }}
              />
            </div>
            <div>
              <input
                type="date"
                name="toDate"
                className="search-graph mLeft-graph"
                id="toDate"
                required
                onChange={(e) => {
                  addGraphData({ ...graphData, toDate: e.target.value });
                }}
              />
            </div>
            {/* <div>
                                    <select id="service" name="service" className="search-graph mLeft-graph"
                                    onChange={(e)=>{
                                        addGraphData({...graphData,service:e.target.value})
                                    }}>
                                        <option>Select Service</option>
                                        <option value="kids">Kids</option>
                                        <option value="enpowerment">Empowerment</option>
                                        <option value="lifestyle">Lifestyle</option>
                                        <option value="faith">Faith</option>
                                        <option value="sports">Sports</option>
                                        <option value="swahili">Swahili</option>
                                        <option value="entertainment">Entertainment</option>
                                        <option value="ndotoStreams">Ndoto</option>
                                    </select>
                                </div> */}
            <div>
              <button className="btn btn-success mLeft-graph">Search</button>
            </div>
          </form>
          {/* </div> */}

          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <button className="btn btn-success">
              Last Days Subscribers Count
            </button>
          </div>

          <div className="top-center container" style={{ height: "100vh" }}>

        <label className="font-graph">Sub</label>
        <label className="graph-color-div" style={{backgroundColor:"green"}}> </label>

        <label className="left-margin-graph-sub font-graph">Unsub</label>
        <label className="graph-color-div" style={{backgroundColor:"orange"}}> </label>

           {/*Graph div */}
            <Chart>
              <ChartTitle text="Sub & Unsub Count" />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  title={{
                    text: "Dates",
                  }}
                  // categories={["Jan", "Feb", "Mar", "Apr"]}
                  categories={graphData.date}
                />
              </ChartCategoryAxis>
              <ChartSeries>
                <ChartSeriesItem
                  // type="bar" //direction horizontal
                  type="column" //direction vertical
                  color="green"
                  stack={{
                    type: "100",
                  }}
                  data={graphData.sub}
                  tooltip={{
                    shared: true,
                    visible: true,
                    background: "#000",
                    template: "#= series.stack.group #, city #= series.name #",
                  }}
                />
                <ChartSeriesItem
                  type="bar"
                  color="orange"
                  data={graphData.unsub}
                  tooltip={{
                    shared: true,
                    visible: true,
                    background: "red",
                    template: "#= series.stack.group #, city #= series.name #",
                  }}
                />
              </ChartSeries>
            </Chart>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Login />
      </div>
    );
  }
};
export default SubUnsubGraph;
