import React,{useState} from "react";
import Auth from "./MyContext";

const ContextState=(props)=>{
    
    const s1={
        "username":""
    }

    const[cred,setCred]=useState(s1);

    const update=(username)=>{
        console.log("user is :",username);
        setCred({"username":username})
    }

    return(
        <Auth.Provider value={{cred,update}}>
            {props.children}
        </Auth.Provider>
    );
}
export default ContextState;