import React,{useState} from "react";
import {ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import "../Css/bulksub.css";
import { bulkUnsubscription } from "../Apis/api";

const BulkUnsubBody=()=>{
   
    const[data,addData]=useState({});

    //method to submit form
    const onSubmit=(e)=>{
        e.preventDefault();
        console.log(data);
        hitOnApi(data);
    }

    //method to hit on api for bulk sub
    const hitOnApi=(send)=>{
        axios.post(`${bulkUnsubscription}`,send)
        .then(
            (response)=>{
                console.log(response.data);
                if(response.data.response==='1')
                {
                    toast.success("Unsub Done Successfully");
                }
                else if(response.data.response==='0')
                {
                    toast.error("Something Went Wrong");
                }
            },
            (error)=>{
                console.log(error);
                toast.error("Something Went Wrong");
            }
        )
    }

    return(
        <div>
              <ToastContainer/>
                <div className="main-bulk">
                <div className="sub-main-bulk">
                    <div>
                        <div>
                        <h2 className="btn btn-outline-secondary font">Bulk Unsub</h2>
                            <form onSubmit={onSubmit}>
                                <div>
                                    {/* <img src={profilee} alt="Email" className="profile"/>  */}
                                    <input type="text" name="ani1" id="ani1"
                                    placeholder="MSISDN1" autoComplete="off"
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani1:e.target.value})
                                    }}/>
                                </div>

                                <div className="second-div">
                                    <input type="text" name="ani2" id="ani2"
                                    placeholder="MSISDN2" autoComplete="off" 
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani2:e.target.value})
                                    }}/>
                                </div>

                                <div className="second-div">
                                    <input type="text" name="ani3" id="ani3"
                                    placeholder="MSISDN3" autoComplete="off" 
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani3:e.target.value})
                                    }}/>
                                </div>

                                <div className="second-div">
                                    <input type="text" name="ani4" id="ani4"
                                    placeholder="MSISDN4" autoComplete="off" 
                                    className="name bttn-div-sub" onChange={(e)=>{
                                        addData({...data, ani4:e.target.value})
                                    }}/>
                                </div>

                                <div className="second-div">
                                    <input type="text" name="ani5" id="ani5"
                                    placeholder="MSISDN5" autoComplete="off" 
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani5:e.target.value})
                                    }}/>
                                </div>

                                <div className="second-div">
                                    <input type="text" name="ani6" id="ani6"
                                    placeholder="MSISDN6" autoComplete="off" 
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani6:e.target.value})
                                    }}/>
                                </div>

                                <div className="second-div">
                                    <input type="text" name="ani7" id="ani7"
                                    placeholder="MSISDN7" autoComplete="off" 
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani7:e.target.value})
                                    }}/>
                                </div>

                                <div className="second-div">
                                    <input type="text" name="ani8" id="ani8"
                                    placeholder="MSISDN8" autoComplete="off" 
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani8:e.target.value})
                                    }}/>
                                </div>

                                <div className="second-div">
                                    <input type="text" name="ani9" id="ani9"
                                    placeholder="MSISDN9" autoComplete="off" 
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani9:e.target.value})
                                    }}/>
                                </div>

                                <div className="second-div">
                                    <input type="text" name="ani10" id="ani10"
                                    placeholder="MSISDN10" autoComplete="off" 
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani10:e.target.value})
                                    }}/>
                                </div>


                                <div className="second-div">
                                    {/* <img src={password} alt="Password" className="email"/> */}
                                    <select id="service" name="service"  className="name"
                                    onChange={(e)=>{
                                        addData({...data,service:e.target.value})
                                    }}>
                                        <option>Select Service</option>
                                        <option value="kids">Kids</option>
                                        <option value="enpowerment">Empowerment</option>
                                        <option value="lifestyle">Lifestyle</option>
                                        <option value="faith">Faith</option>
                                        <option value="sports">Sports</option>
                                        <option value="swahili">Swahili</option>
                                        <option value="entertainment">Entertainment</option>
                                        <option value="ndotoStreams">Ndoto</option>
                                    </select>
                                </div>
                                <div className="bttn-div-sub">
                                    <button className="bttn-sub">Remove</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BulkUnsubBody;