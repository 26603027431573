import React from "react";
import "../Css/common.css";

const TableMis=(prop)=>{
    return(
        <div className="marginFor">
        <table className="table table-dark table-bordered">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Total Subscribers</th>
                        <th>Total Sub Count</th>
                        <th>New Subscribers (100)</th>
                        <th>New Subscribers (500)</th>
                        <th>New Sub Revenue</th>
                        <th>Total Ren Count</th>
                        <th>Renewals (100)</th> 
                        <th>Renewals (500)</th>
                        <th>Ren Revenue</th>
                        <th>Total Revenue</th>
                        <th>Unsub Count</th>
                    </tr>
                </thead>
                {prop.data.map((tbl,index)=>{
                    return(
                        <tbody key={index}>
                            <tr>
                                <td>{tbl.misDate}</td>
                                <td>{tbl.totalBase}</td>
                                <td>{tbl.subCount}</td>
                                <td>{tbl.subPrice100}</td>
                                <td>{tbl.subPrice500}</td>
                                <td>{tbl.subRevenue}</td>
                                <td>{tbl.renCount}</td>
                                <td>{tbl.renPrice100}</td>
                                <td>{tbl.renPrice500}</td>
                                <td>{tbl.renRevenue}</td>
                                <td>{tbl.totalRevenue}</td>
                                <td>{tbl.unsubCount}</td>
                            </tr>
                        </tbody>
                    )
                })}
            </table>

    </div>
    

    );
}
export default TableMis;