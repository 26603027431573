import React from "react";
import Body from "../Components/Body";
import Header from "../Components/Header";
// import Auth from "../Context/MyContext";
import Login from "./Login";
import "../Css/home.css";

const Home=()=>{

        // const context=useContext(Auth);
        // console.log("logged in user is : ",context.cred.username);

        let username=sessionStorage.getItem("username");
        // console.log("username is :: ",username);
        if(username!==null)
        {
            return(
                <div>
                    <Header/>
                    <Body/>
                </div>
            );
        }
        else
        {
            return(
                <div>
                    <Login/>
                </div>
            );
        }
    
}
export default Home;