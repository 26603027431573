// const pre="http://localhost:2930/";
const pre="http://91.205.172.123:7474/NdotoVodacomCCPortal/";
// const pre="https://cci.ndotomediatz.com/";

const login=`${pre}loginCredentials`;
const allSub=`${pre}getAllSubPagination`;
const searchUsingAni=`${pre}searchUsingAni`;
const searchUsingDate=`${pre}searchUsingDate`;
const searchUsingService=`${pre}searchUsingService`;
const subGraphData=`${pre}subGraph`;
const subNewUser=`${pre}subNewUser`;
const bulkSubscription=`${pre}bulkSubscription`;
const bulkUnsubscription=`${pre}bulkUnsub`;
const unsubGraphData=`${pre}unsubGraph`;
const getAllUnsubPagination=`${pre}getAllUnsubPagination`;
const searchUsingAniUnsub=`${pre}searchUsingAniUnsub`;
const searchUsingServiceUnsub=`${pre}searchUsingServiceUnsub`;
const searchUsingDateUnsub=`${pre}searchUsingDateUnsub`;
const unsubUser=`${pre}unsubUser`;
const addToDnd=`${pre}addToDnd`;
const showMis=`${pre}showMIS`;
const showMisGraph=`${pre}showMISGraph`;
const getLastDaysGraph=`${pre}getLastDaysGraph`;

const showFullGraph=`${pre}showFullGraph`;
const subUnsubGraph=`${pre}subUnsubGraph`;

export {login, allSub, searchUsingAni, searchUsingDate, searchUsingService, subGraphData};
export {subNewUser, bulkSubscription, bulkUnsubscription, unsubGraphData};
export {getAllUnsubPagination, searchUsingAniUnsub, searchUsingServiceUnsub};
export {searchUsingDateUnsub, unsubUser, addToDnd};
export {showMis, showMisGraph, getLastDaysGraph};
export {showFullGraph,subUnsubGraph};