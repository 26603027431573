import React from "react";
import BulkSubBody from "../Components/BulkSubBody";
import Header from "../Components/Header";
import Login from "./Login";

const BulkSubscribe=()=>{
    
    let username=sessionStorage.getItem("username");

    if(username!==null)
    {
        return(
            <div>
                <Header/>
                <BulkSubBody/>
            </div>
        );
    }
    else
    {
        return(
            <div>
                <Login/>
            </div>
        );
    }

}
export default BulkSubscribe;