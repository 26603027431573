import React from "react";
import Header from "../Components/Header";
import SubBody from "../Components/SubBody";
import Login from "./Login";

const Subscribe=()=>{
    
    let username=sessionStorage.getItem("username");
    if(username!==null)
    {
        return(
            <div>
                <Header/>
                <SubBody/>
            </div>
        );
    }
    else
    {
        return(
            <div>
                <Login/>
            </div>
        );
    }
}
export default Subscribe;