import React from "react";
import { Link ,useNavigate} from "react-router-dom";
import "../Css/nav.css";
import "../Css/newFile.css";
import "../Css/spinner.css";
import spinner from "../Images/spinner.svg";

const Header=()=>{

    const navigate=useNavigate();

        let username=sessionStorage.getItem("username");
        // console.log("username in header is ",username);

        setTimeout(()=>{
                if(username==='superadmin')
                {
                        document.getElementById("li1").style.display="block";
                        document.getElementById("li2").style.display="block";
                }
        },1000)

    return (
        <>

        {/* <div className="spinner">
            <img className="spinner-item" src={spinner}/>
        </div> */}

        <div className="header-nav">
        <ul>
          <li>
          <Link to="/home" exact="true" className='home-color linkk'>
                  Home 
                  {/* show subscribers in this */}
          </Link>
          </li>
          <li>
          <Link to="/subscribe" exact="true" className='home-color linkk'>
                  Subscribe
                  {/* subscribe a new user */}
          </Link>
          </li>
          <li>
          <Link to="/bulkSub" exact="true" className="home-color linkk">
                  Bulk Sub
          </Link> 
          </li>
          <li>
          <Link to="/bulkUnsub" exact="true" className="home-color linkk">
                  Bulk Unsub
          </Link> 
          </li>
          <li>
          <Link to="/viewUnsub" exact="true" className="home-color linkk">
                  View Unsub
          </Link> 
          </li> 
          <li>
          <Link to="/subUnsubGraph" exact="true" className="home-color linkk">
                Sub Unsub Graph
          </Link>
          </li>
          {/* <li>
          <Link to="/unsubGraph" exact="true" className="home-color linkk">
                Unsub Graph
          </Link>
          </li> */}
          


                <li id="li1" style={{display:"none"}}>
                <Link to="/revenue" exact="true" className="home-color linkk ">
                        Revenue 
                </Link> 
                </li>
          
                <li id="li2" style={{display:"none"}}>
                <Link to="/revenueGraph" exact="true" className="home-color linkk">
                        Revenue Graph
                </Link>
                  </li>

          <li>
          <a className="home-color nav-item linkk" onClick={()=>{
                  // console.log("clicked");
                  sessionStorage.removeItem("username");
                  navigate('/');
              }}>
                  Logout
              </a>
          </li>
        </ul>  
      </div>
      </>
    );
}
export default Header;