import './App.css';
import Header from './Components/Header';
import Menu from './Components/Menu';
import ContextState from './Context/ContextState';

function App() {
  return (
    <div>
      <ContextState>
          <Menu/>
      </ContextState>
    </div>
  );
}

export default App;
