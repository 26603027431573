import React from "react";
import Header from "../Components/Header";
// import RevenueGraphBody from "../Components/RevenueGraphBody";
import RevenueGraphBodyNew from "../Components/RevenueGraphBodyNew";
import Login from "./Login";

const RevenueGraph=()=>{

    let username=sessionStorage.getItem("username");

    if(username!==null)
    {
        if(username==='superadmin')
        {
            //then show that data
            return(
                <div>
                    <Header/>
                    {/* <RevenueGraphBody/> */}
                    <RevenueGraphBodyNew/>
                </div>
            );
        }
        else
        {
            return(
                <div>
                    <Login/>
                </div>
            );
        }
    }
    else
    {
        return(
            <div>
                <Login/>
            </div>
        );
    }
}
export default RevenueGraph;