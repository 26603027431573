import React,{useEffect, useState} from "react";
// import GraphRevenue from "../SubComponents/GraphRevenue";
import axios from "axios";
// import Chart from "react-apexcharts";
// import ApexCharts from "apexcharts";
import "../Css/graph.css"
import { showFullGraph } from "../Apis/api";
import "../Css/home.css";
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";
import '@progress/kendo-theme-default/dist/all.css';

const RevenueGraphBodyNew=()=>{
    const[graphData,addGraphData]=useState({});
    const[myGraph,addMyGraphData]=useState({});

    useEffect(()=>{
        let today = new Date();
        let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        // console.log("date is ",date);

        let priorDate = new Date(new Date().setDate(today.getDate() - 30));
        // console.log("priorDate",priorDate);
        let pDate=priorDate.getFullYear()+'-'+(priorDate.getMonth()+1)+'-'+priorDate.getDate();
        // console.log("pdate ",pDate);
        
        let data={
            "fromDate":pDate,
            "toDate":date
        }
        hitOnApi(data);
    },[]);

    const submitForm=(e)=>{
        e.preventDefault();
        console.log(graphData);
        //hit on api
        hitOnApi(graphData);
    };

    const hitOnApi=(data)=>{
        axios.post(`${showFullGraph}`,data)
        .then(
            (response)=>{
                console.log(response.data)
                // addGraphData(response.data.graphData);
                // addGraphData(response.data.graphData);
                     addMyGraphData(response.data);

                // console.log("myGraph",myGraph);
            },
            (error)=>{
                console.log(error);
            }
        )
    }
  
    return(
        <div className="home-bg" style={{height:"100vh"}}>
             <div className="mTop-graph">
                    {/* <div className="sub-main"> */}
                       <form onSubmit={submitForm} className="main-graph">
                       <div>
                            <input type="date" name="fromDate" className="search-graph" id="fromDate"
                            required onChange={(e)=>{
                                addGraphData({...graphData,fromDate:e.target.value})
                            }}/>
                        </div>
                        <div>
                            <input type="date" name="toDate" className="search-graph mLeft-graph" id="toDate"
                            required onChange={(e)=>{
                                addGraphData({...graphData,toDate:e.target.value})
                            }}/>
                        </div>
                       
                        <div>
                            <button className="btn btn-success mLeft-graph">Search</button>
                        </div>
                       </form>
                    {/* </div> */}

           {/* <div id="chart" className="container">
            </div> */}

              {/*Chart */}

        <div className="container">

<label className="font-graph">Sub100</label>
<label className="graph-color-div" style={{backgroundColor:"red"}}> </label>

<label className="left-margin-graph font-graph">Sub500</label>
<label className="graph-color-div" style={{backgroundColor:"green"}}> </label>

<label className="left-margin-graph font-graph">Ren100</label>
<label className="graph-color-div" style={{backgroundColor:"blue"}}> </label>

<label className="left-margin-graph font-graph">Ren500</label>
<label className="graph-color-div" style={{backgroundColor:"orange"}}> </label>

<Chart>
    <ChartTitle text="Count" />
        <ChartCategoryAxis>
        <ChartCategoryAxisItem
            title={{
            text: "Dates",
            }}
            // categories={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"]}
            categories={myGraph.Dates}
            />

        </ChartCategoryAxis>
    <ChartSeries>
        <ChartSeriesItem type="line" color="red" data={myGraph.Sub100} tooltip= {{
                shared: true,
                visible: true,
                background: "green",
                // template: "#= series.stack.group #, city #= series.name #",
                template:"Sub 100",
              
             }}/>
        <ChartSeriesItem type="line" color="green" data={myGraph.Sub500} tooltip= {{
                shared: true,
                visible: true,
                background: "red",
                template: "#= series.stack.group #, city #= series.name #"
             }}/>
        <ChartSeriesItem type="line" color="blue" data={myGraph.Ren100} tooltip= {{
                shared: true,
                visible: true,
                background: "red",
                template: "#= series.stack.group #, city #= series.name #"
             }}/>
        <ChartSeriesItem type="line" color="orange" data={myGraph.Ren500} tooltip= {{
          shared: true,
          visible: true,
          background: "red",
          template: "#= series.stack.group #, city #= series.name #"
            }}/>
    </ChartSeries>
</Chart>
</div>

        </div>
        </div>
    );
}

export default RevenueGraphBodyNew;