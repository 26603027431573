import React,{useEffect, useState} from "react";
// import axios from "axios";
import { getAllUnsubPagination, searchUsingAniUnsub, searchUsingServiceUnsub,searchUsingDateUnsub } from "../Apis/api";
import "../Css/common.css";
import SimpleApi from "../SubComponents/SimpleApi";
import SearchApi from "../SubComponents/SearchApi";
import TableUnsub from "../SubComponents/TableUnsub";
import spinner from "../Images/spinner.svg";
import "../Css/spinner.css";
import "../Css/home.css";

const UnsubViewBody=()=>{

   //Defining States
   const[data,setData]=useState([]);
   const[cPage,setCurrentPage]=useState();
   // eslint-disable-next-line
   const[tPage,setTotalPages]=useState();

   //Hitting on api to show all sub with pagination
   const hitSimpleApi=async(pageNumber)=>{
       let data=await SimpleApi(getAllUnsubPagination,pageNumber);
       console.log("data::",data);

       let content=data.content;
       let totalPages=data.totalPages;
       let currentPage=data.currentPage;
       // eslint-disable-next-line
       let type=data.type;

    //    console.log("content: ",content);
    //    console.log("totalPages: ",totalPages);
    //    console.log("currentPage ",currentPage);
    //    console.log("type is ",type);

       setData(content);
       setCurrentPage(currentPage);
       setTotalPages(totalPages);

       //Showing & Hiding Paginations
       document.getElementById("subNav").style.display="block";
       document.getElementById("aniNav").style.display="none";
       document.getElementById("serviceNav").style.display="none";
       document.getElementById("dateNav").style.display="none";

       //Getting Next & Previous buttons of Pagination
       let subPrevious=document.getElementById("subPrevious");
       let subNext=document.getElementById("subNext");

       if(currentPage===0)
       {
           subPrevious.style.display="none";
       }
       else
       {
           subPrevious.style.display="block";
       }

       if(currentPage===totalPages)
       {
           subNext.style.display="none";
       }
       else
       {
           subNext.style.display="block";
       }
   };

   //Hitting on api for search by ani, date, service
   const hitSearchApi=async(api,search,searchType,pageNumber)=>{
       let data=await SearchApi(api,search,searchType,pageNumber);
       console.log("data::",data);

       let content=data.content;
       let totalPages=data.totalPages;
       let currentPage=data.currentPage;
       let type=data.type;

    //    console.log("content: ",content);
    //    console.log("totalPages: ",totalPages);
    //    console.log("currentPage ",currentPage);
    //    console.log("type is ",type);

       setData(content);
       setTotalPages(totalPages);
       setCurrentPage(currentPage);

       let previous="";
       let next="";

       if(type==='ani')
       {
            //Showing & Hiding Paginations
           console.log("Inside ani type");

           document.getElementById("subNav").style.display="none";
           document.getElementById("aniNav").style.display="block";
           document.getElementById("serviceNav").style.display="none";
           document.getElementById("dateNav").style.display="none";
   
           previous=document.getElementById("aniPrevious");
           next=document.getElementById("aniNext");    
       }
       else if(type==='service')
       {
           //Showing & Hiding Paginations
           document.getElementById("subNav").style.display="none";
           document.getElementById("aniNav").style.display="none";
           document.getElementById("serviceNav").style.display="block";
           document.getElementById("dateNav").style.display="none";
           
           previous=document.getElementById("servicePrevious");
           next=document.getElementById("serviceNext");    
       }
       else if(type==='date')
       {
           //Showing & Hiding Paginations
           document.getElementById("subNav").style.display="none";
           document.getElementById("aniNav").style.display="none";
           document.getElementById("serviceNav").style.display="none";
           document.getElementById("dateNav").style.display="block";
           
           previous=document.getElementById("datePrevious");
           next=document.getElementById("dateNext");    
       }

       //Getting Next & Previous buttons of Pagination

       if(currentPage===0)
       {
           previous.style.display="none";
       }
       else
       {
           previous.style.display="block";
       }

       if(currentPage===totalPages)
       {
           next.style.display="none";
       }
       else
       {
           next.style.display="block";
       }
   };

   //this will load 1st
   useEffect(()=>{
       hitSimpleApi("0");

       setTimeout(()=>{
        document.getElementById("spin").style.display="none";
    },1000);

   },[]);
  
   //Method for subscription view pagination
   const callPage=(reqPage)=>{
    //    console.log("Requested Page is ",reqPage);
        hitSimpleApi(reqPage);
   };

   //Method to search by ani
   const searchByAni=(reqPage)=>{

       if(reqPage===undefined)
       {
           reqPage=0;
       }

       let ani=document.getElementById("searchAni").value;
    //    console.log("ani is ",ani);

       if(ani==='')
       {
           //call show sub api
           callPage(0);
       }
       else
       {
           hitSearchApi(searchUsingAniUnsub,ani,"ani",reqPage);
       }
   };

   const searchByService=(reqPage)=>{

       if(reqPage===undefined)
       {
           reqPage=0;
       }

       console.log("Request is for page",reqPage);
       let service=document.getElementById("searchService").value;

       if(service==='')
       {
           callPage(0);
       }
       else
       {
           hitSearchApi(searchUsingServiceUnsub,service,"service",reqPage);
       }
   };

   const searchByDate=(reqPage)=>{
       
       if(reqPage===undefined)
       {
           reqPage=0;
       }
       console.log("Request is for page",reqPage);

       let date=document.getElementById("searchDate").value;
       if(date==='')
       {
           callPage(0);
       }
       else
       {
           hitSearchApi(searchUsingDateUnsub,date,"date",reqPage);
       }
   };

   return(
       <div className="home-bg">

        <div className="spinner" id="spin">
            <img className="spinner-item" src={spinner} alt="Src"/>
        </div>
           <div className="container margin flexx"> {/*Search Buttons Div*/}

               <div className="searchInput">
                   <input type="text" name="searchAni" id="searchAni" 
                   placeholder="Search by MSISDN" className="search"
                   autoComplete="off" onChange={(e)=>{
                       setTimeout(function(){
                           // console.log("will run after 3 seconds")
                           searchByAni()
                       },3000)
                   }}/>
               </div>

           <div className="searchDateInput mLeft">
               <input type="date" name="searchDate" id="searchDate" 
               placeholder="Search by Date" className="search"
               autoComplete="off" onChange={(e)=>{
                     setTimeout(function(){
                       // console.log("will run after 2 seconds")
                       searchByDate()
                     },2000)
               }}/>
           </div>

               <div className="searchInput mLeft">
                   <input type="text" name="searchService" id="searchService" 
                   placeholder="Search by Service" className="search"
                   autoComplete="off" onChange={(e)=>{
                       setTimeout(function(){
                           // console.log("will run after 3 seconds")
                           searchByService()
                       },3000)
                   }}/>
               </div>

           </div>
           <div className="container"> {/*tbl_div*/}
               <TableUnsub data={data}/>
           </div>
           <div> {/*Pagination div*/}
               <nav aria-label="Page navigation example" id="subNav">
                   <ul className="pagination center">
                       <li className="page-item" id="subPrevious"><button className="page-link" onClick={()=>callPage(cPage-1)}>Previous</button></li>
                       <li className="page-item"><button className="page-link" onClick={()=>callPage(cPage)}>{cPage+1}</button></li>
                       <li className="page-item"><button className="page-link" onClick={()=>callPage(cPage+1)}>{cPage+2}</button></li>
                       <li className="page-item"><button className="page-link" onClick={()=>callPage(cPage+2)}>{cPage+3}</button></li>
                       <li className="page-item" id="subNext"><button className="page-link" onClick={()=>callPage(cPage+1)}>Next</button></li>
                   </ul>
                </nav>

                <nav aria-label="Page navigation example" id="aniNav" style={{display:"none"}}>
                   <ul className="pagination center">
                       <li className="page-item" id="aniPrevious"><button className="page-link clr2" onClick={()=>searchByAni(cPage-1)}>Previous</button></li>
                       <li className="page-item "><button className="page-link clr2" onClick={()=>searchByAni(cPage)}>{cPage+1}</button></li>
                       <li className="page-item "><button className="page-link clr2" onClick={()=>searchByAni(cPage+1)}>{cPage+2}</button></li>
                       <li className="page-item "><button className="page-link clr2" onClick={()=>searchByAni(cPage+2)}>{cPage+3}</button></li>
                       <li className="page-item " id="aniNext"><button className="page-link clr2" onClick={()=>searchByAni(cPage+1)}>Nextt</button></li>
                   </ul>
                </nav>

                <nav aria-label="Page navigation example" id="serviceNav" style={{display:"none"}}>
                   <ul className="pagination center">
                       <li className="page-item" id="servicePrevious"><button className="page-link clr3" onClick={()=>searchByService(cPage-1)}>Previous</button></li>
                       <li className="page-item"><button className="page-link clr3" onClick={()=>searchByService(cPage)}>{cPage+1}</button></li>
                       <li className="page-item"><button className="page-link clr3" onClick={()=>searchByService(cPage+1)}>{cPage+2}</button></li>
                       <li className="page-item"><button className="page-link clr3" onClick={()=>searchByService(cPage+2)}>{cPage+3}</button></li>
                       <li className="page-item" id="serviceNext"><button className="page-link clr3" onClick={()=>callPage(cPage+1)}>Nexttt</button></li>
                   </ul>
                </nav>

                <nav aria-label="Page navigation example" id="dateNav" style={{display:"none"}}>
                   <ul className="pagination center">
                       <li className="page-item" id="datePrevious"><button className="page-link clr4" onClick={()=>searchByDate(cPage-1)}>Previous</button></li>
                       <li className="page-item"><button className="page-link clr4" onClick={()=>searchByDate(cPage)}>{cPage+1}</button></li>
                       <li className="page-item"><button className="page-link clr4" onClick={()=>searchByDate(cPage+1)}>{cPage+2}</button></li>
                       <li className="page-item"><button className="page-link clr4" onClick={()=>searchByDate(cPage+2)}>{cPage+3}</button></li>
                       <li className="page-item" id="dateNext"><button className="page-link clr4" onClick={()=>searchByDate(cPage+1)}>Nextttt</button></li>
                   </ul>
                </nav>

           </div>
       </div>
   );

}
export default UnsubViewBody;