import React,{useState} from "react";
import axios from "axios";
import {ToastContainer, toast } from 'react-toastify';
import { subNewUser } from "../Apis/api";
import profilee from "../Images/Profilee.png";
import "../Css/sub.css";

const SubBody=()=>{

    const[data,addData]=useState({});

    //method to handle submit form
    const onSubmit=(e)=>{
        e.preventDefault();
        console.log(data);

        //disable submit button for now
        // document.getElementById("myBtn").disabled="true";

        //Send data
        hitOnApi(data);
    }

    //method to hit on api to subscribe
    const hitOnApi=(send)=>{
        axios.post(`${subNewUser}`,send)
        .then(
            (response)=>{
                // console.log(response.data);
                // document.getElementById("myBtn").disabled="false";
                if(response.data.response==='1')
                {
                    //user added successfully
                    toast.success("User Added Successfuly");
                }
                else if(response.data.response==='0')
                {
                    //user already exist
                    toast.error("User Already Exist");
                }
                else if(response.data.response==='2')
                {
                    //something went wrong
                    toast.error("Something Went Wrong");
                }
            },
            (error)=>{
                console.log(error);
                toast.error("Somehing Went Wrong");
            }
        )
    }

    return(
        <div>
             <ToastContainer/>
                <div className="main-sub">
                <div className="sub-main-subscribe">
                    <div>
                    <div className="imgs">
                            <div className="container-image-sub">
                                <img src={profilee} alt="Profile" className="profile-sub"/>
                            </div>
                        </div>
                        <div>
                            {/* <h2 className="btn btn-outline-secondary font">Subscribe User Here</h2> */}
                            <form onSubmit={onSubmit}>
                                <div>
                                    {/* <img src={profilee} alt="Email" className="profile"/>  */}
                                    <input type="text" name="ani" id="ani"
                                    placeholder="MSISDN" autoComplete="off" required
                                    className="name" onChange={(e)=>{
                                        addData({...data, ani:e.target.value})
                                    }}/>
                                </div>

                                <div className="second-div">
                                    {/* <img src={password} alt="Password" className="email"/> */}
                                    <select id="service" name="service" required className="name"
                                    onChange={(e)=>{
                                        addData({...data,service:e.target.value})
                                    }}>
                                        <option>Select Service</option>
                                        <option value="kids">Kids</option>
                                        <option value="enpowerment">Empowerment</option>
                                        <option value="lifestyle">Lifestyle</option>
                                        <option value="faith">Faith</option>
                                        <option value="sports">Sports</option>
                                        <option value="swahili">Swahili</option>
                                        <option value="entertainment">Entertainment</option>
                                        <option value="ndotoStreams">Ndoto</option>
                                    </select>
                                </div>
                                <div className="bttn-div-sub">
                                    <button className="bttn-sub" id="myBtn">Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubBody;