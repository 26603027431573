import React, {useState,useEffect} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer,toast } from "react-toastify";
import "../Css/login.css";
import profile from "../Images/Profilee.png";
import email from "../Images/Email.png";
import password from "../Images/Password.png";
import { login } from "../Apis/api";
// import Auth from "../Context/MyContext";
// import spinner from "../Images/spinner.svg";
import "../Css/loginspinner.css";

const Login=()=>{

    useEffect(()=>{
        document.title="Vodacom||Portal";
        // eslint-disable-next-line
    },[]);

    const[data,addData]=useState({}); //state to store login credentials

    // const context=useContext(Auth);

    const navigate=useNavigate(); //to navigate

    const onSubmit=(e)=>{ //method to run on onsubmit form
        // console.log(data);
        // document.getElementById("spin").style.display="block";
        hitOnApi(data);
        e.preventDefault();
    }

    const hitOnApi=(body)=>{ //method to hit on api using axios
        axios.post(`${login}`,body)
        .then(
            (response)=>{
                // console.log(response);
                // console.log(response.data);
                // document.getElementById("spin").style.display="none";
                let username=response.data.username;
                // console.log("username is ",username);
                if(response.data.username==='admin')
                {
                    //admin is accessing portal
                    // context.update(username);
                    sessionStorage.setItem("username",username);
                    // console.log("setting is ",context.cred.username);
                    navigate("/home");
                }
                else if(response.data.username==='superadmin')
                {
                    //super admin is accessing portal
                    // context.update(username);
                    sessionStorage.setItem("username",username);
                    // console.log("Setting is ",context.cred.username);
                    navigate("/home");
                }
                else if(response.data==='')
                {
                    //wrong credentials
                    toast.error("Wrong Credentials");
                }
                else
                {
                    toast.error("Something Went Wrong");
                }
            },
            (error)=>{
                console.log(error);
                // document.getElementById("spin").style.display="none";
                toast.error("Something Went Wrong");
            }
        )
    }

    return(
        <div>
        <ToastContainer/>
        
        {/*For Spinner*/}
        {/* <div className="login-spinner" id="spin">
            <img className="login-spinner-item" src={spinner}/>
        </div> */}
        

        <div className="main" style={{height:"100vh"}}>
            <div className="sub-main">
                <div>
                    <div className="imgs">
                        <div className="container-image">
                            <img src={profile} alt="Profile" className="profile"/>
                        </div>
                    </div>
                    <div>
                        <h2>Login Here</h2>
                        <form onSubmit={onSubmit}>
                            <div>
                                <img src={email} alt="Email" className="email"/>
                                <input type="text" name="username" id="username"
                                placeholder="Username" autoComplete="off" required
                                className="name" onChange={(e)=>{
                                    addData({...data, username:e.target.value})
                                }}/>
                            </div>
                            <div className="second-div">
                                <img src={password} alt="Password" className="email"/>
                                <input type="password" name="password" id="password"
                                placeholder="Password" autoComplete="off" required
                                className="name" onChange={(e)=>{
                                    addData({...data,password:e.target.value})
                                }}/>
                            </div>
                            <div className="bttn-div">
                                <button className="bttn">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
export default Login;