import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import BulkSubscribe from "../Pages/BulkSubscribe";
import BulkUnsubscribe from "../Pages/BulkUnsubscribe";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import Revenue from "../Pages/Revenue";
import RevenueGraph from "../Pages/RevenueGraph";
import SubGraph from "../Pages/SubGraph";
import Subscribe from "../Pages/Subscribe";
import SubUnsubGraph from "../Pages/SubUnsubGraph";
import UnsubGraph from "../Pages/UnsubGraph";
import ViewUnsub from "../Pages/ViewUnsub";

const Menu=()=>{
    return(
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login/>} exact="true"></Route>
                    <Route path="/home" element={<Home/>} exact="true"></Route>
                    <Route path="/subscribe" element={<Subscribe/>} exact="true"></Route>
                    <Route path="/bulkSub" element={<BulkSubscribe/>} exact="true"></Route>
                    <Route path="/subUnsubGraph" element={<SubUnsubGraph/>} exact="true"></Route>
                    <Route path="/bulkUnsub" element={<BulkUnsubscribe/>} exact="true"></Route>
                    {/* <Route path="/unsubGraph" element={<UnsubGraph/>} exact="true"></Route> */}
                    <Route path="/viewUnsub" element={<ViewUnsub/>} exact="true"></Route>
                    <Route path="/revenue" element={<Revenue/>} exact="true" ></Route>
                    <Route path="/revenueGraph" element={<RevenueGraph/>} exact="true"></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}
export default Menu;